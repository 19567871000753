

/* Style Metete Dashboard */

* { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
   font-family: 'Poppins', sans-serif;
}

.h-box { min-height: 100vh; }

.bg-size-cover {
    background-size: cover !important;
}

.bg-repeat-0 {
    background-repeat: no-repeat !important;
}

.bg-position-center {
    background-position: center !important;
}

.d-none {
    display: none !important;
}


@media (min-width: 1200px) {
.d-xl-block {
    display: block !important;
}
}

/* BACKGROUND COLOR */

.bg-login{
  background-color: #f8f9fd;
}

.bg-box{
    background-color: #1b214e;
    border: 1px solid #dee2e6;
}



.bg_gradient {
background: rgb(31,52,91);
background: -moz-linear-gradient(145deg, rgba(31,52,91,1) 0%, rgba(28,225,208,1) 100%);
background: -webkit-linear-gradient(145deg, rgba(31,52,91,1) 0%, rgba(28,225,208,1) 100%);
background: linear-gradient(145deg, rgba(31,52,91,1) 0%, rgba(28,225,208,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f345b",endColorstr="#1ce1d0",GradientType=1);
}


/* BUTTON */


.btn-link {
    color: #404040;
    text-decoration: none;
    font-size: 0.9rem;
}

.btn-login{
  color: #3364ff;
  border: 1px solid #3364ff; 
}


.btn-login:hover {
    color: #ffffff;
    background-color: #3364ff;
}


.btn-primario{
  color: #ffffff;
  background-color: #3364ff;
  border: 1px solid #dee2e6;  
}


.btn-primario:hover {
    color: #ffffff;
    background-color: #1b214e;
    border: 1px solid #dee2e6;
}


/* BORDER */

.border-box{
    border: 1px solid #b8b8d2;
}


.navbar {
    background-color: #1b214e;
    border: 1px solid #dee2e6;
}

.nav-link {
    color: #ffffff;
}

.nav-link:hover {
    color: #3364ff;
}
.nav-link:focus {
    color: #dedede;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #3364ff;
}

@media screen and (max-width: 2560px) and (min-width: 769px) {
.b-radius {
    border-radius: 50rem !important;
}
}

@media (max-width: 768px) and (min-width: 320px) {
.b-radius {
     border-radius: 1rem !important;
}

.border-end {
    border-color: #1b214e !important;
}
}


.box-circle{
    height: 6rem;
    width: 6rem;
}


/* 
Generic Styling, for Desktops/Laptops 
*/
table { 
  width: 100%; 
  border-collapse: collapse; 
}
/* Zebra striping */
tr:nth-of-type(odd) { 
  background: #eee; 
}
th { 
  background: #1b214e; 
  color: white; 
  font-weight: bold; 
}
td, th { 
  padding: 6px; 
  border: 1px solid #ccc; 
  text-align: left; 
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
        display: block; 
    }
    
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    
    tr { border: 1px solid #ccc; }
    
    td { 
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee; 
        position: relative;
        padding-left: 50%; 
    }
    
    td:before { 
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%; 
        padding-right: 10px; 
        white-space: nowrap;
    }
    .banner-custom-size {
        width: 100px;   /* Establece el ancho del rectángulo */
        height: 50px;   /* Establece la altura del rectángulo */
    }
    /*
    Label the data
    */
    td:nth-of-type(1):before { content: "First Name"; }
    td:nth-of-type(2):before { content: "Last Name"; }
    td:nth-of-type(3):before { content: "Job Title"; }
    td:nth-of-type(4):before { content: "Favorite Color"; }
    td:nth-of-type(5):before { content: "Wars of Trek?"; }
    td:nth-of-type(6):before { content: "Secret Alias"; }
    td:nth-of-type(7):before { content: "Date of Birth"; }
    td:nth-of-type(8):before { content: "Dream Vacation City"; }
    td:nth-of-type(9):before { content: "GPA"; }
    td:nth-of-type(10):before { content: "Arbitrary Data"; }
}